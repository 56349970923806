import React, { useCallback } from "react";

import toast, { Toaster } from "react-hot-toast";
import { sendEmail } from "../sendEmail";
import { EMAIL_ERROR, EMAIL_SUCCESS } from "../constants";
import type { Section } from "../types";
import { RImage } from "./RImage";

export interface ContactUsProps {
	section: Section;
}

export const ContactUs: React.FC<ContactUsProps> = ({ section }) => {
	const [email, setEmail] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const onSubmitForm = useCallback(async () => {
		setLoading(true);
		const result = await sendEmail({ email, type: "HaveQuestions" });
		if (result.ok) {
			toast.success(EMAIL_SUCCESS, {
				position: "top-center",
				duration: 5000,
			});
			setEmail("");
		} else {
			toast.error(EMAIL_ERROR);
		}
		setLoading(false);
	}, [email, setEmail, setLoading]);
	return (
		<>
			<div className="relative py-24  ">
				{section.image && (
					<RImage
						{...section.image}
						className="absolute brightness-40 inset-0 -z-10 h-full w-full object-cover"
					/>
				)}

				<div className="bg-gradient-to-b  relative isolate overflow-hidden  px-6 py-16 lg:py-32   lg:px-8 gap-10 flex flex-col ">
					<div className="mx-auto max-w-2xl fadeInUp text-center flex flex-col gap-8">
						<h2 className="font-serif  font-bold tracking-tight text-stone-50 text-4xl 2xs:text-4xl xs:text-5xl sm:text-5xl lg:text-7xl">
							{section.title}
						</h2>
						<p className=" text-lg leading-8 text-stone-50">
							{section.content}
						</p>

						<form
							className="flex flex-1 justify-center"
							onSubmit={(e) => {
								e.preventDefault();
								onSubmitForm();
							}}
						>
							<div className="flex flex-1 flex-row fadeInUp gap-2 max-w-[450px]">
								<input
									disabled={loading}
									id="email"
									name="email"
									type="email"
									onInput={(e) => setEmail(e.currentTarget.value)}
									value={email}
									required
									aria-describedby="email-description"
									autoComplete="email"
									placeholder="Enter your email"
									className="transition-all flex-1 block  w-full rounded-md py-3  px-4 sm:px-3.5 border-teal-700 border-2  text-stone-900 text-lg  sm:text-lg md:text-lg sm:leading-6  outline outline-transparent outline-offset-0 outline-4     focus-within:border-teal-600/65"
								/>
								<button
									disabled={loading}
									type="submit"
									className="w-28  flex justify-center items-center transition-all rounded-md bg-teal-500    px-6  sm:px-5  tracking-wide text-lg md:text-xl font-medium text-white shadow-sm  hover:bg-teal-600 border-2  border-teal-700  focus-visible:outline-teal-600 "
								>
									{loading ? (
										<svg
											className="animate-spin text-white"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<title>Loading</title>
											<path
												d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
												opacity=".50"
												fill="#4dd4e5"
											/>
											<path
												d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
												fill="#fff"
											/>
										</svg>
									) : (
										<span>Submit</span>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Toaster />
		</>
	);
};
